import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { globalstate$ } from '@smf/ui-util-app';
import ConfigDefaultContainer from '../Config';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={'/config/*'}
          element={
            globalstate$.value.isLoggedInUser ? (
              <ConfigDefaultContainer />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          render={() => (
            <Route path="*" element={<Navigate to="/" replace />} />
          )}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
