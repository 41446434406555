import React from 'react';
import { Grid, styled } from '@mui/material';
import ConfigSidebar from '../ConfigSidebar';

const MainGridContainer = styled(Grid)(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.background.dullDark,
}));
const SidebarGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    maxWidth: 240,
  },
  [theme.breakpoints.up('md')]: {
    minWidth: 240,
  },
}));

export default function ConfigDefaultContainer() {
  return (
    <MainGridContainer container direction="row" wrap="nowrap">
      <SidebarGrid
        item
        container
        // sm={2}
        // lg={2}
        // xl={1}
      >
        <ConfigSidebar />
      </SidebarGrid>
    </MainGridContainer>
  );
}
