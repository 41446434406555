import { ThemeProvider } from '@mui/material';
import { globalstate$, theme, splitRootFactory } from '@smf/ui-util-app';
import { QueryClientProvider, QueryClient } from 'react-query';
import { SplitClient, SplitFactory } from '@splitsoftware/splitio-react';
import App from './containers/Routes';

export default function Root() {
  const queryClient = new QueryClient();
  return (
    <SplitFactory factory={splitRootFactory}>
      <SplitClient
        splitKey={globalstate$._value?.userData?.email?.toLowerCase()}
      >
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </ThemeProvider>
      </SplitClient>
    </SplitFactory>
  );
}
