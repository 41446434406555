import { Divider, styled, alpha } from '@mui/material';
import React from 'react';

const CustomDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.divider, 0.3),
}));

export default function WhiteDivider({ ...other }) {
  return <CustomDivider variant="middle" {...other} />;
}
