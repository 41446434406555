import { useEffect, useState } from 'react';
import { globalstate$ } from '@smf/ui-util-app';

export const useRxjsState = () => {
  const [rxjsState, setState] = useState({});

  useEffect(() => {
    if (globalstate$) {
      const subscription = globalstate$.subscribe((globalstate) => {
        setState(globalstate);
      });

      return () => {
        if (subscription) {
          subscription.unsubscribe();
        }
      };
    }
  }, []);

  return { rxjsState };
};
