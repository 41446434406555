import React from 'react';
import { Drawer, Toolbar, styled } from '@mui/material';

const CustomDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.background.configLighterDark,
    borderRight: 'none',
  },
  height: '100%',
}));

function SideBar({
  children,
  variant = 'permanent',
  nonToolbar,
  modalProps = {},
  paperProps = {},
}) {
  return (
    <>
      <CustomDrawer
        variant={variant}
        ModalProps={{ ...modalProps }}
        PaperProps={{ ...paperProps }}
      >
        {!nonToolbar && <Toolbar />}
        {children}
      </CustomDrawer>
    </>
  );
}

export default SideBar;
